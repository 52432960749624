import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { isNull } from "lodash"
import Seo from "components/Seo"
import PageHeader from "components/PageHeader"

export default function CaseLayout({ location, title, description, children }) {
  const data = useStaticQuery(graphql`
    query WordPressCaseArchiveLayout {
      allWpCaseCategory {
        nodes {
          id
          slug
          uri
          name
          count
        }
      }
    }
  `)

  const categories = data.allWpCaseCategory.nodes

  return (
    <div>
      <Seo title={"컨설팅 성공사례"} />
      <PageHeader title={title} description={description} />
      {categories && (
        <div className="w-full lg:container mx-auto px-4 lg:px-8">
          <ul className="flex flex-row flex-wrap -mx-3 mb-8 -my-1">
            <li key={"all-cases"} className="px-3 my-1">
              <Link
                to={"/cases"}
                className={`inline-block rounded-md text-gray-500 hover:text-gray-900`}
                activeClassName="text-gray-900"
                getProps={({ location }) => {
                  const pathnameSplited = location.pathname.split("/")
                  if (
                    pathnameSplited.includes("cases") &&
                    !pathnameSplited.includes("categories")
                  ) {
                    return { className: "text-gray-900" }
                  }
                }}
              >
                All case studies
              </Link>
            </li>
            {categories.map(item => {
              if (isNull(item.count)) {
                return null
              }
              return (
                <li key={item.id} className="px-3 my-1">
                  <Link
                    to={item.uri}
                    className={`inline-block rounded-md text-gray-500 hover:text-gray-900`}
                    activeClassName="text-gray-900"
                    getProps={event => {
                      const { href, location } = event
                      const pathnameSplited = location.pathname.split("/")
                      if (
                        href === location.pathname ||
                        (href.split("/").splice(0, 4).join("/") ===
                          location.pathname &&
                          pathnameSplited.includes("cases") &&
                          pathnameSplited.includes("categories"))
                      ) {
                        return { className: "text-gray-900" }
                      }
                    }}
                    to={`/cases/categories/${item.slug}`}
                  >
                    {item.name}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      )}
      <div>{children}</div>
    </div>
  )
}
