import React from "react"
import { Link } from "gatsby"

export default function Pagination({ previousPagePath, nextPagePath }) {
  return (
    <div className="flex flex-row flex-wrap justify-center">
      {previousPagePath && (
        <Link
          to={previousPagePath}
          className="w-10 h-10 border border-gray-300 rounded-full inline-flex items-center justify-center mx-1 text-gray-500 hover:text-gray-900 transition duration-200"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </Link>
      )}
      {nextPagePath && (
        <Link
          to={nextPagePath}
          className="w-10 h-10 border border-gray-300 rounded-full inline-flex items-center justify-center mx-1 text-gray-500 hover:text-gray-900 transition duration-200"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </Link>
      )}
    </div>
  )
}
