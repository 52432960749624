import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Placeholder from "images/symbol.jpeg"

export default function CaseCard({
  featuredImage,
  consultant,
  eyebrow,
  title,
  url,
}) {
  return (
    <Link
      to={url}
      className="flex w-full h-full hover:shadow-md transition duration-300 lg:hover:-translate-y-4 border border-gray-200 rounded-lg overflow-hidden"
    >
      <div className="pb-4 w-full flex flex-col justify-between">
        <div>
          <div className="relative">{featuredImage}</div>

          <div className="pt-4 pb-4 px-4">
            {eyebrow && <div className="mb-3">{eyebrow}</div>}
            <h3 className="font-medium text-gray-700 text-lg xl:text-xl leading-snug break-words line-clamp-2">
              {title}
            </h3>
          </div>
        </div>
        <div className="px-4 pt-4">
          {consultant &&
            consultant.map(item => {
              return (
                <div key={item.id}>
                  <div className="flex flex-row flex-wrap">
                    <div className="block relative overflow-hidden mr-3 w-12 h-12 bg-gray-200 rounded-full border border-gray-100">
                      {!item.featuredImage ? (
                        <img
                          src={Placeholder}
                          className="absolute w-full h-full inset-0 object-cover"
                        />
                      ) : (
                        <GatsbyImage
                          style={{
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            inset: 0,
                            objectFit: "cover",
                          }}
                          image={
                            item.featuredImage?.node?.localFile?.childImageSharp
                              ?.gatsbyImageData
                          }
                          alt={item.title}
                        />
                      )}
                    </div>
                    <div className="flex flex-col flex-wrap justify-center">
                      <p className="text-gray-500">{item?.consultant?.role}</p>
                      <h4 className="text-gray-900 font-medium">
                        {item.title}
                      </h4>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </Link>
  )
}
